import { ConfigType } from "../utils/requestAPI";

const userDashboardConfig = {
  //Documents API Routes
  getDocumentByType: (documentType: string): ConfigType => ({
    method: "get",
    url: `/documents?documentType=${documentType}`,
  }),
  getSingleDocument: (documentId: string): ConfigType => ({
    method: "get",
    url: `/documents/${documentId}`,
  }),
  getDocumentByUserId: (userId: string, limit?: number): ConfigType => {
    let query = `?leadId=${userId}`;
    if (limit) {
      query += `&limit=${limit}`;
    }
    return {
      method: "get",
      url: `/documents/${query}`,
    };
  },
  //Updates API Routes
  getAllUpdates: (): ConfigType => ({
    method: "get",
    url: `/activities`,
  }),
  //Updates API Routes
  getUpdatesByUserId: (userId: string): ConfigType => ({
    method: "get",
    url: `/activities/?leadId=${userId}`,
  }),
  //Grants API Routes
  getGrantsByUserId: (userId: string): ConfigType => ({
    method: "get",
    url: `/grants/?leadId=${userId}`,
  }),
  //Grants API Routes
  getWorkStagesByUserId: (userId: string): ConfigType => ({
    method: "get",
    url: `/stages/?leadId=${userId}`,
  }),
  //Payments API Routes
  getTransactionByLeadId: (id: string): ConfigType => ({
    method: "get",
    url: `/transactions/users/${id}`,
  }),
  //Payments API Routes
  getDataOfLoggedInUser: (userId: string): ConfigType => ({
    method: "get",
    url: `/leads/${userId}`,
  }),
};

export default userDashboardConfig;
