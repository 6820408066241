import { HotspotsSection } from "../../components/Shared";
import EnergyRetrofitCard from "../../components/WhatIsRetrofit/EnergyRetrofitCard";
import RetrofitBanner from "../../components/WhatIsRetrofit/RetrofitBanner";
import EnergyfixCTASection from "../../components/Shared/EnergyfixCTASection";
import { Helmet } from "react-helmet-async";

const WhatIsRetrofit = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - What is retrofitting?</title>
        <meta
          name="description"
          content="Retrofitting boosts energy efficiency, comfort, and sustainability. Upgrade insulation, ventilation, and heating for lower bills and fewer emissions."
        />
        <link rel="canonical" href="/what-is-retrofitting" />
      </Helmet>
      <RetrofitBanner />
      <EnergyRetrofitCard />
      <HotspotsSection />
      <EnergyfixCTASection />
    </>
  );
};

export default WhatIsRetrofit;
