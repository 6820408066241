import moment from "moment";
import { DataType } from ".";
import { FaRegFilePdf } from "react-icons/fa";
import { LiaDownloadSolid } from "react-icons/lia";
import { ActionWrapper, FileDownloadWrapper } from "./style";
export const formatDocumentsTableData = (
  data: DataType[],
  activeTab: number
): DataType[] => {
  return filterData(data, activeTab).map((report) => {
    console.log("report", report.updatedAt);

    const newReport: any = Object.entries(report)
      .map(([key, value]) => {
        switch (key) {
          case "name":
            return { label: "title", value: value };
          case "documentURL":
            return { label: "document", value: <FileDownload value={value} /> };
          case "updatedAt":
            return {
              label: "updated_date",
              value: moment(value).startOf("minute").fromNow(),
            };
          default:
            return null;
        }
      })
      .filter(Boolean); // Remove null entries

    // newReport.push({ label: "action", value: <Action /> });

    return newReport;
  });
};
export const Action = () => {
  return (
    <ActionWrapper>
      <div
        className="view"
        role="button"
        onClick={() => alert("Work In Progress")}
      >
        View
      </div>
      <div
        className="view"
        role="button"
        onClick={() => alert("Work In Progress")}
      >
        Edit
      </div>
      <div
        className="delete"
        role="button"
        onClick={() => alert("Work In Progress")}
      >
        Delete
      </div>
    </ActionWrapper>
  );
};

const clickedTab = ["REPORT", "GRANT", "CONTRACT", "CERTIFICATE", "ADDITIONAL"];
export const filterData = (data: DataType[], activeTab: number) => {
  const filteredData = data.filter(
    (item) => item.documentType === clickedTab[activeTab]
  );
  return filteredData;
};

export const FileDownload = ({ value }: { value: string }) => {
  return (
    <FileDownloadWrapper>
      <FaRegFilePdf size={25} color="red" />

      <a
        href={value}
        download
        target="_blank"
        rel="noreferrer"
        className="link"
      >
        <LiaDownloadSolid size={25} />
      </a>
    </FileDownloadWrapper>
  );
};
