import { Content, Title, WrapperStyled, TypographyWrapper } from "./style";
import Typography from "@shared/designComponents/Typography";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import theme from "@shared/theme";
import { Container } from "@shared/designComponents";

const AboutSection = () => {
  return (
    <Container marginVertical={50}>
      <WrapperStyled>
        <SectionTitle className="header">
          ABOUT <ColorText color={theme.colors.primary}>US.</ColorText>
        </SectionTitle>
        <Content>
          <Title>
            <Typography color={theme.colors.secondary} className="title">
              “Experience the Difference: Our Expert Retrofit Specialists Make
              Your Home Energy Dreams a Reality.”
            </Typography>
          </Title>
          <TypographyWrapper>
            <Typography style={{ fontSize: "16px", textAlign: "justify" }}>
              EnergyFix is a One Stop Shop which looks after all the key stages
              of a home energy retrofit, from retrofit design through to project
              completion and management of SEAI grant funding. We are leading
              contractors and experienced retrofit specialists to offer you
              expert guidance through an often complex process. Our retrofit
              specialists aim to provide homeowners with peace of mind, quality
              assurance, and, when the retrofit is complete, the best home
              possible. We believe that the best way to achieve a comfortable,
              healthy and low-carbon home is through a whole house retrofit
              powered by a renewable energy heat pump. Insulation,
              air-tightness, advanced ventilation and a retrofit specialist
              designed heating system work perfectly together to deliver the
              best possible energy system for your home.
            </Typography>
            <Typography style={{ fontSize: "16px" }}>
              EnergyFix is a joint venture between HouseBuild.com and
              construction company BHA. Our ambition is to guide Irish
              homeowners to warmer, comfortable and more energy efficient homes
              and support the achievement of Ireland’s climate action targets
              through a commitment to delivering 35,000 home retrofits by 2030.{" "}
            </Typography>
            <Typography style={{ fontSize: "16px" }}>
              EnergyFix is committed to a greener and more sustainable future
              for Ireland. We look forward to helping you retrofit your home the
              the future and together we will achieve the ambitious targets set.
            </Typography>
          </TypographyWrapper>
        </Content>
      </WrapperStyled>
    </Container>
  );
};

export default AboutSection;
