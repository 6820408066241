import { useRoot } from "@shared/context/RootProvider";
import { Typography } from "@shared/designComponents";
import Accordion from "@shared/designComponents/Accordion/Accordion";
import colors from "@shared/theme/colors";
import { useWindowSize } from "@uidotdev/usehooks";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "../../designComponents/Dropdown";
import { PROFILE_ROUTE, USER_DASHBOARD_ROUTE } from "../../routes/routePaths";
import energyConstants from "@shared/constants";
import { requestAPI } from "@shared/utils";
import leadsConfig from "@shared/apiConfigs/leads";
import { useEffect, useState } from "react";
type TypeAccountRoutes = {
  key: string;
  route: string;
  name: string;
  type?: "button";
};

const accountRoutes: TypeAccountRoutes[] = [
  {
    key: "route-101",
    name: "Dashboard",
    route: USER_DASHBOARD_ROUTE,
  },
  {
    key: "route-102",
    name: "My Profile",
    route: `${USER_DASHBOARD_ROUTE}/${PROFILE_ROUTE}`,
  },
];

function AccountSignIn() {
  const { auth } = useRoot();
  const { width } = useWindowSize();
  const { _id: id } = auth;
  const { pathname } = useLocation();
  const [userData, setUserData] = useState("fname");
  const { clearAuth } = useRoot();
  const handleLogout = () => {
    clearAuth();
    localStorage.removeItem(energyConstants.LOCAL_STORAGE_KEY.user);
    toast.success("Logged out successfully");
  };

  const userDetails = async () => {
    const data = await requestAPI(leadsConfig.getLeadById(id));
    setUserData(data.firstName);
  };

  useEffect(() => {
    userDetails();
  }, []);

  if (width && width < 992) {
    return (
      <Accordion>
        <Accordion.Header className="header-nav__user-profile">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: "12px",
            }}
          >
            <div className="header-nav__user-profile--img">
              {auth?.avatar ? (
                <img src={auth?.avatar} alt="user" />
              ) : (
                <img src="/assets/images/default-user.svg" alt="user" />
              )}
            </div>

            <Typography size="sm" color={colors.secondary}>
              {auth?.email}
            </Typography>
          </div>
        </Accordion.Header>
        <Accordion.Content animationType="display">
          <ul
            style={{
              marginLeft: "42px",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            {accountRoutes.map((route) => {
              return (
                <li key={route.key}>
                  <Link to={route.route}>
                    <Typography
                      fontWeight="normal"
                      size="sm"
                      color={colors.tableBody}
                    >
                      {route.name}
                    </Typography>
                  </Link>
                </li>
              );
            })}
            <li>
              <button onClick={handleLogout} style={{ cursor: "pointer" }}>
                <Typography
                  fontWeight="normal"
                  size="sm"
                  color={colors.tableBody}
                >
                  Sign Out
                </Typography>
              </button>
            </li>
          </ul>
        </Accordion.Content>
      </Accordion>
    );
  }

  return (
    <Dropdown
      trigger={
        <div className="header-nav__user-profile" style={{ cursor: "pointer" }}>
          <div className="header-nav__user-profile--img">
            {auth?.avatar ? (
              <img src={auth?.avatar} alt="user" />
            ) : (
              <img src="/assets/images/default-user.svg" alt="user" />
            )}
          </div>

          <Typography size="sm" color={colors.secondary}>
            {/* {auth?.email} */}
            {userData}
          </Typography>
        </div>
      }
    >
      <ul style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
        {accountRoutes.map((route) => {
          return (
            <li
              key={route.key}
              style={{
                backgroundColor:
                  pathname === route.route ? colors.dropdownHover : "",
              }}
            >
              <Link to={route.route}>
                <Typography
                  fontWeight="normal"
                  size="sm"
                  color={colors.tableBody}
                >
                  {route.name}
                </Typography>
              </Link>
            </li>
          );
        })}
        <li>
          <button onClick={handleLogout} style={{ cursor: "pointer" }}>
            <Typography fontWeight="normal" size="sm" color={colors.tableBody}>
              Sign Out
            </Typography>
          </button>
        </li>
      </ul>
    </Dropdown>
  );
}

export default AccountSignIn;
