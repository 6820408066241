import { Helmet } from "react-helmet-async";
import GrantsTable from "../../components/CostAndGrants/SEAIGrants/GrantsTable";
import SEAIGrantsHeader from "../../components/CostAndGrants/SEAIGrants/SEAIGrantsHeader";

export default function CostAndGrants() {
  return (
    <>
      <Helmet>
        <title>EnergyFix - SEAI Grants</title>
        <meta
          name="description"
          content="EnergyFix offers one-stop-shop grants for homeowners, facilitating full energy retrofits with ease."
        />
        <link rel="canonical" href="/cost-and-fees" />
      </Helmet>
      <SEAIGrantsHeader />
      <GrantsTable />
    </>
  );
}
