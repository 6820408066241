import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const WrapperStyled = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 40px;
  max-width: 1050px;
  margin: 0 auto;

  & .header {
    text-align: center;

    @media (min-width: ${screenSizes.md}) {
      margin-left: 220px;
    }
    @media (min-width: ${screenSizes.lg}) {
      margin-left: 220px;
    }
  }
`;

export const Title = styled.div`
  min-width: 200px;

  & .title {
    line-height: 32px;
    font-size: 20px;
    font-style: italic;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: ${screenSizes.lg}) {
    /* display: flex; */
    flex-direction: row;
    gap: 30px;
  }
`;

export const TypographyWrapper = styled.div`
  display: flex,
  flexDirection: column,
  gap: 30px,
`;
