import { Helmet } from "react-helmet-async";
import {
  AboutSection,
  CardSection,
  ImageSection,
} from "../../components/About";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - About Us</title>
        <meta
          name="description"
          content="Expert retrofit specialists making energy-efficient homes a reality. From design to completion, we guide you every step."
        />
        <link rel="canonical" href="/about-us" />
      </Helmet>
      <AboutSection />
      <ImageSection />
      <CardSection />
    </>
  );
};

export default AboutUs;
