import styled from "styled-components";

export const TermsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
  li {
    list-style-position: inside;
  }
`;

export const TermsRowStyled = styled.div`
  display: flex;
  gap: 10px;
`;
