import { Helmet } from "react-helmet-async";
import PropertyDocuments from "../../../components/CostAndGrants/VacantHomesGrant/PropertyDocuments";
import RefurbishmentGrant from "../../../components/CostAndGrants/VacantHomesGrant/RefurbishmentGrant";
import EnergyfixCTASection from "../../../components/Shared/EnergyfixCTASection";

const VacantHomesGrant = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - Vacant Home Grants</title>
        <meta
          name="description"
          content="Combine the One Stop Shop Grant Scheme with Vacant Property Refurbishment Scheme for up to €50,000 to renovate homes built before 1993."
        />
        <link rel="canonical" href="/cost-and-fees/vacant-homes-grant" />
      </Helmet>
      <RefurbishmentGrant />
      <PropertyDocuments />
      <EnergyfixCTASection />
    </>
  );
};

export default VacantHomesGrant;
