import { Outlet } from "react-router-dom";
import GlobalStyle from "@shared/style/globalStyle";
import RootProvider from "@shared/context/RootProvider";
import { ThemeProvider } from "styled-components";
import theme from "@shared/theme";
import { Provider } from "react-redux";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "@shared/designComponents/ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <RootProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <Helmet>
              <title>EnergyFix - Brighten Your Home, Lighten Your Bill</title>
            </Helmet>
            <ScrollToTop />
            <GlobalStyle />
            <Outlet />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnHover
              theme="light"
              // progressStyle={{ background: theme.colors.primary }}
            />
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </RootProvider>
  );
}

export default App;
