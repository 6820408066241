import React from "react";
import styled from "styled-components";
import theme from "@shared/theme";

interface DotProps {
  active: boolean;
}

const Dot = styled.button<DotProps>`
  border: none;
  background: ${(props) => (props.active ? theme.colors.primary : "#ccc")};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  transition: background 0.3s ease;

  &:hover,
  &:focus {
    background: #26c9a4;
  }
`;

interface CustomDotProps {
  onClick: () => void;
  active: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => (
  <Dot onClick={onClick} active={active} />
);

export default CustomDot;
