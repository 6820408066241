import styled from "styled-components";

import { flexStart } from "../../style/reusableStyle";

export const IconLeft = styled.span``;

export const IconRight = styled.span``;

export const InputContainerStyled = styled.div`
  ${flexStart};
  gap: 10px;
  flex-wrap: nowrap;
  outline: 1px solid ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 7px 20px;
  border-radius: 8px;
  height: 40px;
`;
export const InputStyled = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: inherit;
  line-height: 1.5rem;
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.ad_disableButtonText};
  }
`;
