import React from "react";
import { TermsContainerStyled, TermsRowStyled } from "./style";
import { Typography } from "@shared/designComponents";

const TermsAndCondition = () => {
  return (
    <TermsContainerStyled>
      <Typography
        style={{ marginBottom: "20px" }}
        variant="h2"
        size="lg"
        fontWeight="medium"
      >
        Term and Conditions
      </Typography>
      <div
        style={{
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <TermsRowStyled>
          <Typography fontWeight="medium">1.</Typography>
          <Typography>
            If you proceed to work with EnergyFix, you understand and agree that
            all energy credits generated (under the SEAI energy Supplier
            Obligation Scheme) will be assigned to Energyfix and the value of
            these is used to offset a portion of the costs associated with your
            project. You will not be able to sell/reassign/claim these credits
            again under any other scheme.
          </Typography>
        </TermsRowStyled>
        <TermsRowStyled>
          <Typography fontWeight="medium">2.</Typography>
          <Typography>
            {" "}
            Projects will be assessed on a case-by-case basis regarding
            location, suitability, and contractor availability.
          </Typography>
        </TermsRowStyled>
        <TermsRowStyled>
          <Typography fontWeight="medium">3.</Typography>
          <Typography>
            Grant funding for eligible works under the Energyfix programme is at
            the full discretion of SEAI.
          </Typography>
        </TermsRowStyled>
        <TermsRowStyled>
          <Typography fontWeight="medium">4.</Typography>
          <Typography>
            {" "}
            Notwithstanding the services provided by Energyfix, it is the
            responsibility of the appointed contractor to carry out the works as
            specified in the contract for works.
          </Typography>
        </TermsRowStyled>
        <TermsRowStyled>
          <Typography fontWeight="medium">5.</Typography>
          <Typography>
            The project must be completed within the timeframe given in SEAI’s
            ‘letter of offer’.
          </Typography>
        </TermsRowStyled>
      </div>
    </TermsContainerStyled>
  );
};

export default TermsAndCondition;
