import { FaRegFilePdf } from "react-icons/fa";
import { LiaDownloadSolid } from "react-icons/lia";
import { WorkStagesDataType } from ".";
import { ButtonsStyled } from "./style";
import { Typography } from "@shared/designComponents";
import theme from "@shared/theme";
export const formatStagesTableData = (
  data: WorkStagesDataType[]
): WorkStagesDataType[] => {
  return data.map((stage) => {
    const newStage: any = Object.entries(stage)
      .map(([key, value]) => {
        switch (key) {
          case "name":
            return { label: "title", value: value };
          case "amount":
            return { label: "amount", value: `€ ${value.toLocaleString()}` };
          case "status":
            return { label: "status", value: <Status value={value} /> };
          default:
            return null;
        }
      })
      .filter(Boolean); // Remove null entries

    // newReport.push({ label: "action", value: <Action /> });

    return newStage;
  });
};
const Status = ({ value }: { value: string }) => {
  return (
    <ButtonsStyled>
      <Typography size={"sm"} fontWeight="normal" color={theme.colors.white}>
        {value}
      </Typography>
    </ButtonsStyled>
  );
};

export default Status;
