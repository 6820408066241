import { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { StyledLargeTable, TableWrapper } from "./styled";
import { useWindowSize } from "@uidotdev/usehooks";
import Typography from "../Typography";
import colors from "../../theme/colors";
import React from "react";
type TableHeaderType = {
  label: string;
  name: string;
};

export type TableDataType = {
  label: string;
  value: string | string[] | ReactNode;
};

interface TableProps extends ComponentProps<"table"> {
  data: {
    header: TableHeaderType[];
    body: TableDataType[][];
    keyToHide?: string;
  };
}

const Table = ({
  data,
  keyToHide,
  ...props
}: PropsWithChildren<TableProps>) => {
  const { width } = useWindowSize();

  // Add a wrapper to handle the overflow and horizontal scrolling
  return (
    <TableWrapper>
      <LargeTable tableData={data} keyToHide={keyToHide || "_id"} {...props} />
    </TableWrapper>
  );
};

export function LargeTable({
  tableData,
  keyToHide,
  ...props
}: {
  tableData: { header: TableHeaderType[]; body: TableDataType[][] };
  keyToHide: string;
}) {
  return (
    <StyledLargeTable {...props}>
      <thead>
        <tr>
          {tableData.header.map((head) => (
            <th key={head.label}>
              <Typography
                variant="span"
                color={colors.secondary}
                size="sm"
                fontWeight="medium"
              >
                {head.name}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.body.length > 0 ? (
          tableData.body.map((tableRow, index) => (
            <tr key={`tableRow-${index}`}>
              {tableRow.map((tableData, i) => {
                if (tableData.label === keyToHide) {
                  return null;
                }

                if (
                  typeof tableData.value !== "string" &&
                  !Array.isArray(tableData.value)
                ) {
                  return (
                    <td key={`${tableData.label}-${index}-${i}`}>
                      {tableData.value}
                    </td>
                  );
                }
                if (typeof tableData.value === "string") {
                  return (
                    <td
                      key={`${tableData.label}-${index}-${i}`}
                      className="table-data__list"
                    >
                      <Typography
                        variant="p"
                        size="sm"
                        style={{ fontSize: "13px", whiteSpace: "nowrap" }}
                      >
                        {tableData.value}
                      </Typography>
                    </td>
                  );
                }

                return (
                  <td
                    key={`${tableData.label}-${index}-${i}`}
                    className="table-data__list"
                  >
                    {tableData.value.map((tableText) => (
                      <React.Fragment key={tableText}>
                        {tableText}
                      </React.Fragment>
                    ))}
                  </td>
                );
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={tableData.header.length}
              style={{ textAlign: "center" }}
            >
              <Typography variant="p" color={colors.footerBlack}>
                No data available
              </Typography>
            </td>
          </tr>
        )}
      </tbody>
    </StyledLargeTable>
  );
}

export default Table;
