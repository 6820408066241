import * as yup from "yup";
export default (stepIndex: string) => {
  switch (stepIndex) {
    case "1-0":
      return yup.object({
        addressLine1: yup.string().required("Enter your first address line."),
        addressLine2: yup.string().required("Enter your second address line."),
      });
    case "1-1":
      return yup.object({
        cityId: yup.string().required("Select your city."),
        eirCode: yup.string().required("Enter your eircode."),
      });
    case "1-2":
      return yup.object({
        homeType: yup.string(),
        homeAge: yup.string(),
        ber: yup.string().required("Enter select ber."),
        // mprn: yup.string(),
      });
    default:
      return yup.object({
        firstName: yup.string().required("Enter your first name."),
        lastName: yup.string().required("Enter your last name."),
        phoneNumber: yup.string().required("Enter your phone number."),
        emailAddress: yup.string().required("Enter your email address."),
        subscribeNewsletter: yup
          .string()
          .required("Select yes/no considering your preference."),
        agreeTermsAndConditions: yup
          .boolean()
          .oneOf([true], "Agree terms and conditions to submit your form.")
          .required("Agree terms and conditions to submit your form."),
      });
  }
};
