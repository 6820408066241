import { MainLayout } from "../layouts/Layout";
import AboutUs from "../pages/AboutUs";
import Login from "../pages/Auth/Login";
import Home from "../pages/Home";
import Faqs from "../pages/Faqs";
import WhatIsRetrofit from "../pages/WhatIsRetrofit";
import Services from "../pages/Services";
import Test from "../pages/Test";
import {
  ABOUTUS_ROUTE,
  HOME_ROUTE,
  FAQS_ROUTE,
  LOGIN_ROUTE,
  REGISTER_INTEREST_PATH,
  RETROFIT_ROUTE,
  SERVICES_ROUTE,
  TEST_ROUTE,
  CUSTOMER_CHARTER_ROUTE,
  RETROFIT_ROUTE_RENEWABLE,
  RETROFIT_ROUTE_INSULATION,
  RETROFIT_ROUTE_VENTILATION,
  RETROFIT_ROUTE_AIRTIGHTNESS,
  RETROFIT_ROUTE_DOOR,
  RETROFIT_ROUTE_SOLAR,
  COST_GRANT_PATH,
  FINANCE_PATH,
  VACANT_HOMES_GRANT_PATH,
  PROFILE_ROUTE,
  TERMS_POLICY_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  USER_DASHBOARD_ROUTE,
  TERMS_CONDITIONS_ROUTE,
} from "./routePaths";
import CustomerCharter from "../components/CustomerCharter";
import RegisterInterestPage from "../pages/RegisterInterest";
import {
  AirtightnessCard,
  DoorCard,
  InsulationCard,
  RenewableCard,
  SolarCard,
  VentilationCard,
} from "../components/WhatIsRetrofit/individualCardSection";
import CostAndGrants from "../pages/CostAndGrants";
import VacantHomesGrant from "../pages/CostAndGrants/VacantHomesGrant";
import Finance from "../pages/CostAndGrants/Finance";
import PageNotFound from "../pages/PageNotFound";
import Profile from "../pages/Profile";
import Terms from "../components/TermsOfUse";
import UpdatePassword from "../pages/Auth/UpdatePassword";
import UserDashboard from "../components/UserDashboard";
import PrivateLayout from "../layouts/Layout/PrivateLayout";
import TermsAndCondition from "../pages/Terms";

const routes: IRoutes[] = [
  {
    id: 1111111,
    path: TEST_ROUTE,
    component: Test,
    layout: MainLayout,
    // isProtected: false,
  },
  {
    id: 101,
    path: HOME_ROUTE,
    component: Home,
    layout: MainLayout,
    // isProtected: true,
  },
  {
    id: 102,
    path: LOGIN_ROUTE,
    component: Login,
    layout: MainLayout,
    // isProtected: true,
    redirectToOnAuth: HOME_ROUTE,
  },
  {
    id: 103,
    path: ABOUTUS_ROUTE,
    component: AboutUs,
    layout: MainLayout,
    // isProtected: true,
  },
  {
    id: 107,
    path: REGISTER_INTEREST_PATH,
    component: RegisterInterestPage,
    layout: MainLayout,
  },

  {
    id: 104,
    path: RETROFIT_ROUTE,
    component: WhatIsRetrofit,
    layout: MainLayout,
    children: [
      {
        id: 1041,
        path: RETROFIT_ROUTE_RENEWABLE,
        component: RenewableCard,
      },
      {
        id: 1042,
        path: RETROFIT_ROUTE_INSULATION,
        component: InsulationCard,
      },
      {
        id: 1043,
        path: RETROFIT_ROUTE_VENTILATION,
        component: VentilationCard,
      },
      {
        id: 1044,
        path: RETROFIT_ROUTE_AIRTIGHTNESS,
        component: AirtightnessCard,
      },
      {
        id: 1045,
        path: RETROFIT_ROUTE_DOOR,
        component: DoorCard,
      },
      {
        id: 1046,
        path: RETROFIT_ROUTE_SOLAR,
        component: SolarCard,
      },
    ],
  },
  {
    id: 105,
    path: SERVICES_ROUTE,
    component: Services,
    layout: MainLayout,
    // isProtected: true,
  },
  {
    id: 106,
    path: FAQS_ROUTE,
    component: Faqs,
    layout: MainLayout,
  },

  {
    id: 108,
    path: COST_GRANT_PATH,
    component: CostAndGrants,
    layout: MainLayout,
    children: [
      {
        id: 1081,
        path: FINANCE_PATH,
        component: Finance,
      },
      {
        id: 1083,
        path: VACANT_HOMES_GRANT_PATH,
        component: VacantHomesGrant,
      },
    ],
  },
  {
    id: 109,
    path: "*",
    component: PageNotFound,
    layout: MainLayout,
  },
  {
    id: 110,
    path: CUSTOMER_CHARTER_ROUTE,
    component: CustomerCharter,
    layout: MainLayout,
  },
  {
    id: 112,
    path: TERMS_POLICY_ROUTE,
    component: Terms,
    layout: MainLayout,
  },
  {
    id: 113,
    path: UPDATE_PASSWORD_ROUTE,
    component: UpdatePassword,
    layout: MainLayout,
  },
  {
    id: 114,
    path: USER_DASHBOARD_ROUTE,
    component: UserDashboard,
    layout: PrivateLayout,
    children: [
      {
        id: 1141,
        path: PROFILE_ROUTE,
        component: Profile,
      },
    ],
  },
  {
    id: 115,
    path: TERMS_CONDITIONS_ROUTE,
    component: TermsAndCondition,
    layout: MainLayout,
  },
];

export interface IRoutes {
  id: number;
  path: string;
  component: React.FC;
  layout: React.FC<React.PropsWithChildren<{ extraInfo?: any }>>;
  isProtected?: boolean;
  redirectToOnAuth?: string;
  role?: string[];
  restrictTo?: string[];
  allowTo?: string[];
  children?: Array<IChildren>;
}

export interface IChildren {
  id: number;
  path: string;
  component: React.FC;
  children?: Array<IChildren>;
}

export default routes;
