import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const UpdateCardStyled = styled.div`
  width: 356px;
  height: 126px;
  padding: 12px 12px 17px 29px;
  margin: 0 auto;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.grayFA};
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2),
      0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  .iconBox {
    flex-shrink: 0;
    height: 13px;
    width: 13px;
    border-radius: 50%;
  }

  .details {
    margin-left: 19px;

    .name {
      margin: 12px 0px;
    }

    .title {
      margin-bottom: 6px;
    }
  }

  @media (min-width: ${screenSizes.md}) {
    width: 100%;

    .iconBox {
      height: 19px;
      width: 19px;
    }
  }
`;
