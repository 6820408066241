import { Helmet } from "react-helmet-async";
import {
  VideoSection,
  SubmitInterest,
  HeroSection,
  HomeCarouselSection,
} from "../../components/Home";
import { HotspotsSection } from "../../components/Shared";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - Brighten Your Home, Lighten Your Bill</title>
        <meta
          name="description"
          content="Transform your home with EnergyFix: Comfort, savings, and sustainability. 8-step process for a warmer, greener home. Enquire now!"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <HeroSection />
      <VideoSection />
      <HomeCarouselSection />
      <SubmitInterest />
      <HotspotsSection />
    </>
  );
};

export default Home;
