import styled from "styled-components";

export const SelectWrapper = styled.div`
  /* height: 50px; */
  padding: 7px 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  height: 40px;
  border-radius: 8px;
  justify-content: space-between;
`;

export const OptionsContainerStyled = styled.div`
  background-color: #f5f5f5;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  margin-top: 5px;
  left: 0;
  top: 100%;
  z-index: 100;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;

  right: 0;
  padding: 20px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  & .option {
    padding: 10px 6px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.dropdownHover};
      color: black;
    }
  }
`;
