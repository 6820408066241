import styled from "styled-components";
import colors from "../../theme/colors";

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const StyledLargeTable = styled.table`
  width: 100%;
  table-layout: auto;

  & thead {
    background-color: ${colors.gray.lighter};
    border: 1px solid ${colors.gray.lighter};
  }

  & th {
    padding: 12px 20px;
    text-align: left;
  }

  & tbody {
    td {
      padding: 12px 20px;
      border: 1px solid ${colors.disable};
      text-align: left;
    }
  }

  & .table-data__list {
    .quill .ql-editor > p {
      white-space: nowrap;
      font-family: poppins;
      color: black;
      font-weight: 400;
    }
  }

  & .table-data__list p:not(:last-child) {
    margin-bottom: 10px;
  }
`;
