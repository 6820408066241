import React, { useState } from "react";
import {
  SectionTitle,
  ColorText,
  Button,
  Container,
} from "@shared/designComponents";
import { cardItems } from "./cardData";
import theme from "@shared/theme";
import { CarouselWrapperStyled, Section, NavWrapper } from "./style";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { devices } from "@shared/theme/device";
import styled from "styled-components";
import CustomDot from "./CustomCarouselDots";

const responsive = {
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3.5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 953, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 765, min: 420 },
    items: 2,
    slidesToSlide: 1,
  },
  sm: {
    breakpoint: { max: 565, min: 400 },
    items: 1.5,
    slidesToSlide: 1,
  },
  xs: {
    breakpoint: { max: 415, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomCarouselContainer = styled.div`
  max-width: 1000px;
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 0 auto;

  @media (max-width: 953px) {
    max-width: 750px;
  }
  @media (max-width: 765px) {
    max-width: 550px;
  }
  @media (max-width: 565px) {
    max-width: 400px;
  }
  @media (max-width: 415px) {
    max-width: 265px;
  }

  .custom-carousel-container {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    padding: 20px;

    @media (${devices.tablet}) {
      padding: 10px;
    }
  }
`;

const HomeCarouselSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = React.useRef(null);

  const handleNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleSlideChange = (previousSlide, { currentSlide }) => {
    setCurrentSlide(currentSlide);
  };

  return (
    <Container marginVertical={120}>
      <CarouselWrapperStyled>
        <Section>
          <SectionTitle variant="h2" className="section-header">
            8 steps away from a{" "}
            <ColorText color={theme.colors.primary}>Greener</ColorText> and{" "}
            <ColorText color={theme.colors.yellowGreen}>Warmer</ColorText> home.
          </SectionTitle>
          <NavWrapper>
            <Button variant="icon" onClick={handlePrevSlide} reverseIcon />
            <Button variant="icon" onClick={handleNextSlide} />
          </NavWrapper>
        </Section>
        <div>
          <CustomCarouselContainer>
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlay={false}
              arrows={false}
              showDots={false}
              keyBoardControl={true}
              customTransition="transform 0.5s ease"
              transitionDuration={500}
              containerClass="custom-carousel-container"
              itemClass="custom-carousel-item"
              afterChange={handleSlideChange}
            >
              {cardItems}
            </Carousel>
          </CustomCarouselContainer>
          <div className="custom-dot-list">
            {cardItems.map((_, index) => (
              <CustomDot
                key={index}
                onClick={() =>
                  carouselRef.current && carouselRef.current.goToSlide(index)
                }
                active={currentSlide === index}
              />
            ))}
          </div>
        </div>
      </CarouselWrapperStyled>
    </Container>
  );
};

export default HomeCarouselSection;
