import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Accordion from "@shared/designComponents/Accordion/Accordion";
import {
  ColorText,
  Container,
  Flex,
  Typography,
  ReadOnlyEditor,
  Divider,
} from "@shared/designComponents";
import {
  FAQContainer,
  FAQContent,
  Header,
  Headers,
  Tab,
  TabsContainer,
  Wrapper,
} from "./style";
import axios from "axios";
import PageTitle from "@shared/designComponents/PageTitle";
import theme from "@shared/theme";
import { requestAPI } from "@shared/utils";
import faqConfig from "@shared/apiConfigs/faqs";
import { Helmet } from "react-helmet-async";

type FaqType = {
  _id: string;
  title: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
};
export type FaqWithCategoryType = {
  _id: string;
  category: string;
  faq: FaqType[];
};
const CategoriesTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [faqData, setFaqData] = useState<FaqWithCategoryType[]>([]);
  const [offsetTop, setOffsetTop] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requestAPI(faqConfig.getAllFaqs());
        setFaqData([...response.data]);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchData();
  }, []);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    const selectedCategory = document.getElementById(`faq-category-${index}`);
    if (selectedCategory) {
      window.scrollTo({
        top: selectedCategory?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const categories = faqData.map((faq) => faq.category);

  useEffect(() => {
    if (categories && categories.length > 0) {
      categories.forEach((cat, idx) => {
        const selectedCategory = document.getElementById(`faq-category-${idx}`);
        if (selectedCategory) {
          const topOffset = selectedCategory?.offsetTop;
          offsetTop[idx] = topOffset;
          setOffsetTop(offsetTop);
        }
      });
    }
  }, [categories]);

  useEffect(() => {
    const handleOnScroll = () => {
      const scrollTop = window.scrollY + 220;
      offsetTop.forEach((top, index) => {
        if (top <= scrollTop) {
          setSelectedTab(index);
        }
      });
    };
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>EnergyFix - Common Questions Corner</title>
        <meta
          name="description"
          content="Explore EnergyFix's comprehensive guide covering benefits, grants, qualifications, costs, measures, and FAQs for a one-stop-shop retrofit."
        />
        <link rel="canonical" href="/faqs" />
      </Helmet>
      <Container marginVertical={50}>
        <Wrapper>
          <TabsContainer>
            <Headers>
              <PageTitle>
                <ColorText color={theme.colors.secondary}>FAQs</ColorText>
              </PageTitle>
              <Typography style={{ marginBottom: "20px", marginTop: "10px" }}>
                Here are some common questions about{" "}
                <ColorText color={theme.colors.primary}>EnergyFix.</ColorText>
              </Typography>
            </Headers>
            <Typography
              style={{
                marginLeft: "10px",
                color: "#333333",
                fontSize: "14px",
                minWidth: "250px",
              }}
            >
              Table of Content
            </Typography>
            {categories.length > 0
              ? categories.map((category, index) => (
                  <Tab
                    key={index}
                    isActive={index === selectedTab}
                    onClick={() => handleTabClick(index)}
                  >
                    <Typography className="category-tabs-title">
                      {category}
                    </Typography>
                  </Tab>
                ))
              : null}
          </TabsContainer>
          <Flex direction="column">
            <Header>
              <PageTitle>
                <ColorText color={theme.colors.secondary}>FAQs</ColorText>
              </PageTitle>
              <Typography style={{ marginBottom: "20px", marginTop: "10px" }}>
                Here are some common questions about{" "}
                <ColorText color={theme.colors.primary}>EnergyFix.</ColorText>
              </Typography>
            </Header>
            <FAQContainer>
              {Array.isArray(faqData) && faqData.length > 0 ? (
                faqData.map((faqCategory, index) => {
                  return (
                    <Flex
                      direction="column"
                      gap="16px"
                      key={faqCategory._id + "index"}
                      id={`faq-category-${index}`}
                    >
                      <Typography
                        fontWeight="semibold"
                        size="xl"
                        color={theme.colors.primary}
                      >
                        {faqCategory.category}
                      </Typography>
                      <Flex direction="column" gap="15px">
                        {faqCategory.faq.map((faq, index) => (
                          <Accordion key={index}>
                            <Accordion.Header>
                              {index + 1}. {faq.title}
                            </Accordion.Header>
                            <Accordion.Content>
                              <ReadOnlyEditor content={faq.content} />
                              <Divider spaceY="10px" />
                            </Accordion.Content>
                          </Accordion>
                        ))}
                      </Flex>
                    </Flex>
                  );
                })
              ) : (
                <Flex alignItems="center" justifyContent="center">
                  <Typography>No FAQ registered yet!!</Typography>
                </Flex>
              )}
            </FAQContainer>
          </Flex>
        </Wrapper>
      </Container>
    </>
  );
};

export default CategoriesTabs;
