import { ConfigType, ParamsType } from "../utils/requestAPI";
export type LeadParams = {
  berAdvisorID?: string;
  customerServiceId?: string;
  _id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  addressOne?: string;
  addressTwo?: string;
  ber?: string;
  cityId?: string;
  mprn?: string;
  phoneCallNotes?: string;
  homeAge?: string;
  homeType?: string;
  assessmentDate?: string;
  eirCode?: string;
  regionId?: string;
  interestedWork?: string[];
  emailNotification?: boolean;
  ageOfProperty?: string;
  typeOfProperty?: string;
  interest?: string[];
  typeOfQuestions?: string;
};
export type AssignLeadData = {
  customerServiceAssistantId?: string;
  berAdvisorIds?: string;
  leadId?: string;
  assignLead?: any[];
};

export type LeadData = Pick<
  LeadParams,
  | "firstName"
  | "lastName"
  | "email"
  | "mobileNumber"
  | "addressOne"
  | "addressTwo"
  | "eirCode"
  | "ber"
  | "cityId"
  | "mprn"
  | "regionId"
  | "homeAge"
  | "homeType"
  | "interestedWork"
  | "emailNotification"
  | "ageOfProperty"
  | "typeOfProperty"
  | "interest"
  | "typeOfQuestions"
  
>;

const leadsConfig = {
  getAllLeads: (params: ParamsType & { isClient: boolean }): ConfigType => {
    if (params.search) {
      params.search = params.search;
    }
    return {
      method: "get",
      url: `/leads`,
      params,
    };
  },
  getAssignedLead: (): ConfigType => {
    return {
      method: "get",
      url: `/leads/assigned`,
    };
  },

  createLead: (data: LeadData): ConfigType => ({
    method: "post",
    url: "/leads",
    data,
  }),
  updateLead: (id: string, data: LeadData): ConfigType => ({
    method: "put",
    url: `/leads/${id}`,
    data,
  }),

  assignLead: (data: AssignLeadData, leadId: string): ConfigType => ({
    method: "post",
    data,
    url: `/leads/${leadId}/assign-to`,
  }),

  getLeadById: (id: string): ConfigType => ({
    method: "get",
    url: `/leads/${id}`,
  }),
  deleteLeadById: (id: string): ConfigType => ({
    method: "delete",
    url: `/leads/${id}`,
  }),
  updateLeadById: (
    data: LeadParams,
    id: string,
    isPhoned?: boolean,
    grantApprovedQuery?: boolean
  ): ConfigType => {
    let query = `${id}`;

    if (typeof isPhoned === "undefined") {
      query += `?isAssessmentGrantApproved=${grantApprovedQuery}`;
    }
    if (typeof grantApprovedQuery === "undefined") {
      query += `?isPhoned=${isPhoned}`;
    }
    if (
      typeof grantApprovedQuery === "undefined" &&
      typeof isPhoned === "undefined"
    ) {
      query = `${id}`;
    }

    return {
      method: "put",
      data,
      url: `/leads/${query}`,
    };
  },
};

export default leadsConfig;
