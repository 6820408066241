import {
  Button,
  CircularStepper,
  ColorText,
  Container,
  Flex,
  FormError,
  Input,
  Modal,
  SectionTitle,
} from "@shared/designComponents";
import Checkbox from "@shared/designComponents/Checkbox";
import Radio from "@shared/designComponents/Radio";
import Select from "@shared/designComponents/Select";
import Typography from "@shared/designComponents/Typography";
import colors from "@shared/theme/colors";
import { useWindowSize } from "@uidotdev/usehooks";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { SubmitInterestStyled } from "./style";
import validation from "./validation";
import { requestAPI } from "@shared/utils";
import leadsConfig from "@shared/apiConfigs/leads";
import cityConfig from "@shared/apiConfigs/cities";
import { GapBox } from "../../../pages/RegisterInterest/style";

type cityType = {
  _id: string;
  value: string;
  label: string;
  name: string;
};

const numericalStepperLabel = ["Property Details", "Personal Details"]; //[1,2] not [0,1]
const propertyDetailsLabel = [
  "Address Details",
  "Country Details",
  "Home Details",
];
const typeOfProperty = [
  { label: "Bungalow", value: "Bungalow" },
  { label: "Detached", value: "Detached" },
  { label: "Semi Detached", value: "Semi Detached" },
  { label: "Mid Terrace", value: "Mid Terrace" },
  { label: "Apartment", value: "Apartment" },
  { label: "Multi Unit", value: "Multi Unit" },
  { label: "Commercial", value: "Commercial" },
  { label: "Others", value: "Others" },
];

const ageOfProperty = [
  { label: "Pre 1960", value: "Pre 1960" },
  { label: "1960 1980", value: "1960 1980" },
  { label: "1980 2000", value: "1980 2000" },
  { label: "2001 2011", value: "2001 2011" },
  { label: "2011 onwards", value: "2011 onwards" },
  { label: "Don't know the year", value: "Don't know the year0" },
];
const ber = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "A3", value: "A3" },
  { label: "B1", value: "B1" },
  { label: "B2", value: "B2" },
  { label: "B3", value: "B3" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "C3", value: "C3" },
  { label: "D1", value: "D1" },
  { label: "D2", value: "D2" },
  { label: "E1", value: "E1" },
  { label: "E2", value: "E2" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "I don't know my rating", value: "I don't know my rating" },
];
const initialState = {
  addressLine1: "",
  addressLine2: "",
  cityId: "",
  eirCode: "",
  typeOfProperty: "",
  ageOfProperty: "",
  ber: "",
  mprn: "",
  firstName: "",
  interest: [],
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  subscribeNewsletter: "",
  agreeTermsAndConditions: false,
};
type InitialStateType = typeof initialState;

const SubmitInterest = () => {
  const theme = useTheme();
  const [isModalOpen, setModalOpen] = useState({
    status: "",
    errorMessage: "",
    isOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([{ value: "", label: "" }]);

  const [activeForm, setActiveForm] = useState({
    numericalState: 1,
    propertyLabelIndex: 0,
  });
  const formikRef = useRef<FormikProps<InitialStateType>>(null);
  const { width = 0 } = useWindowSize();

  const isSmallScreen = width && width < 768;

  const fetchCityData = async () => {
    try {
      const response = await requestAPI(
        cityConfig.getAllCities({
          limit: 100,
        })
      );
      const data = response.data;
      const newData = data.map((item: cityType) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      const sortedCities = newData.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setCities(sortedCities);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCityData();
  }, []);

  const handleFormSubmit = async (values: InitialStateType) => {
    if (activeForm.numericalState === 1 && !isSmallScreen) {
      const propertyLabelIndex = activeForm.propertyLabelIndex + 1;
      if (propertyLabelIndex < 3) {
        setActiveForm((prev) => ({
          numericalState: 1,
          propertyLabelIndex,
        }));
      } else {
        setActiveForm((prev) => ({
          ...prev,
          numericalState: 2,
        }));
      }
      return;
    }

    if (activeForm.numericalState === 1 && isSmallScreen) {
      setActiveForm({ numericalState: 2, propertyLabelIndex: 0 });
      return;
    }

    const {
      addressLine1,
      addressLine2,
      ber,
      cityId,
      eirCode,
      emailAddress,
      firstName,
      lastName,
      mprn,
      interest,
      phoneNumber,
      ageOfProperty,
      typeOfProperty,
    } = values;
    setLoading(true);
    try {
      const response = await requestAPI(
        leadsConfig.createLead({
          addressOne: addressLine1,
          addressTwo: addressLine2,
          ageOfProperty: ageOfProperty,
          ber,
          eirCode,
          email: emailAddress,
          firstName,
          lastName,
          mobileNumber: phoneNumber,
          mprn,
          interest,
          cityId: cityId,
          typeOfProperty: typeOfProperty,
        })
      );
      if (response._id) {
        setModalOpen({
          status: "success",
          errorMessage: "",
          isOpen: true,
        });
        // } else if (response.error) {
        //   setModalOpen({
        //     status: "error",
        //     errorMessage: response.error,
        //     isOpen: true,
        //   });
        // } else {
        //   setModalOpen({
        //     status: "error",
        //     errorMessage: "Some thing went wrong",
        //     isOpen: true,
        //   });
      }
      if (formikRef.current) {
        formikRef.current.resetForm();
        setActiveForm({ numericalState: 1, propertyLabelIndex: 0 });
      }
    } catch (error) {
      setModalOpen({
        status: "error",
        errorMessage: error || "Some thing went wrong",
        isOpen: true,
      });
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };
  const validationKey = `${activeForm.numericalState}-${activeForm.propertyLabelIndex}`;
  const validationSchema = validation(validationKey);

  function onBackPressed() {
    setActiveForm((prev) => {
      if (prev.numericalState === 2) {
        return {
          numericalState: 1,
          propertyLabelIndex: isSmallScreen ? 0 : 2,
        };
      }

      return {
        numericalState: 1,
        propertyLabelIndex: prev.propertyLabelIndex - 1,
      };
    });
  }

  return (
    <Container marginVertical={120}>
      <SubmitInterestStyled>
        <div className="left-container">
          <Typography
            variant="h2"
            className="left-container__heading"
            size={["xl", "_2xl", "_2xl", "_4xl"]}
            color={colors.normalText}
            fontWeight="bold"
          >
            <Typography
              variant="span"
              size={["xl", "_2xl", "_2xl", "_4xl"]}
              color={colors.normalText}
              fontWeight="bold"
            >
              Submit your Interest
            </Typography>
            <br />
            Tell us about your{" "}
            <Typography
              variant="span"
              color={colors.primary}
              size={["xl", "_2xl", "_2xl", "_4xl"]}
              fontWeight="bold"
            >
              Home?
            </Typography>
          </Typography>
          {activeForm.numericalState === 1 ? (
            <Flex
              direction="column"
              gap="18px"
              className="left-container__select-heading"
            >
              {propertyDetailsLabel.map((label, idx) => {
                const isActive =
                  propertyDetailsLabel[activeForm?.propertyLabelIndex] ===
                  label;
                return (
                  <Flex alignItems="center" key={label}>
                    <Typography
                      style={{ width: "30px" }}
                      size={isActive ? "_2xl" : "md"}
                      fontWeight={isActive ? "semibold" : "semibold"}
                      color={
                        isActive ? theme.colors.primary : theme.colors.gray8A
                      }
                    >
                      {isActive && `${idx + 1}.`}
                    </Typography>
                    <Typography
                      style={{ width: "fit-content" }}
                      size={isActive ? "xl" : "md"}
                      fontWeight={isActive ? "semibold" : "semibold"}
                      color={
                        isActive ? theme.colors.secondary : theme.colors.gray8A
                      }
                    >
                      {label}
                    </Typography>
                  </Flex>
                );
              })}
            </Flex>
          ) : null}

          <div className=""></div>
        </div>
        <div className="right-container">
          <CircularStepper
            steps={numericalStepperLabel}
            activeIndex={activeForm.numericalState}
            width="200px"
            style={{ marginBottom: "50px" }}
          />
          <GapBox />
          <Formik
            initialValues={initialState}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ setFieldValue, errors, values, touched, submitForm }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  {((activeForm.numericalState === 1 &&
                    activeForm.propertyLabelIndex === 0) ||
                    (isSmallScreen && activeForm.numericalState === 1)) && (
                    <div>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Address Line 1*
                        </Typography>
                        <Input
                          name="addressLine1"
                          value={values["addressLine1"]}
                          placeholder="Please enter your address line 1"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("addressLine1", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.addressLine1 && errors?.addressLine1
                              ? errors?.addressLine1
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Address Line 2*
                        </Typography>
                        <Input
                          name="addressLine2"
                          value={values["addressLine2"]}
                          placeholder="Please enter your address line 2"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("addressLine2", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.addressLine2 && errors?.addressLine2
                              ? errors?.addressLine2
                              : ""
                          }
                        />
                      </Flex>
                    </div>
                  )}
                  {((activeForm.numericalState === 1 &&
                    activeForm.propertyLabelIndex === 1) ||
                    (isSmallScreen && activeForm.numericalState === 1)) && (
                    <div>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          City/County*
                        </Typography>
                        <Select
                          options={cities}
                          onSelect={(selectedOption) => {
                            setFieldValue("cityId", selectedOption);
                          }}
                          selected={values.cityId}
                          placeholder="Select City"
                          onChange
                        />

                        <FormError
                          error={
                            touched?.cityId && errors?.cityId
                              ? errors?.cityId
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Eircode*
                        </Typography>
                        <Input
                          name="eirCode"
                          value={values["eirCode"]}
                          placeholder="Please enter your eircode"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("eirCode", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.eirCode && errors?.eirCode
                              ? errors?.eirCode
                              : ""
                          }
                        />
                      </Flex>
                    </div>
                  )}
                  {((activeForm.numericalState === 1 &&
                    activeForm.propertyLabelIndex === 2) ||
                    (isSmallScreen && activeForm.numericalState === 1)) && (
                    <div>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Type of property
                        </Typography>
                        <Select
                          options={typeOfProperty}
                          onSelect={(selectedOption) => {
                            setFieldValue("typeOfProperty", selectedOption);
                          }}
                          selected={values.typeOfProperty}
                          placeholder="Type of property"
                        />

                        <FormError
                          error={
                            touched?.typeOfProperty && errors?.typeOfProperty
                              ? errors?.typeOfProperty
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Age of property
                        </Typography>
                        <Select
                          options={ageOfProperty}
                          onSelect={(selectedOption) => {
                            setFieldValue("ageOfProperty", selectedOption);
                          }}
                          selected={values.ageOfProperty}
                          placeholder="Select Age of Property"
                        />

                        <FormError
                          error={
                            touched?.ageOfProperty && errors?.ageOfProperty
                              ? errors?.ageOfProperty
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          BER*
                        </Typography>
                        <Select
                          options={ber}
                          onSelect={(selectedOption) => {
                            setFieldValue("ber", selectedOption);
                          }}
                          selected={values.ber}
                          placeholder="Select BER"
                        />

                        <FormError
                          error={touched?.ber && errors?.ber ? errors?.ber : ""}
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          MPRN
                        </Typography>
                        <Input
                          name="mprn"
                          value={values["mprn"]}
                          placeholder="Please enter MPRN of home"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("mprn", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        {/* <FormError
                          error={
                            touched?.mprn && errors?.mprn ? errors?.mprn : ""
                          }
                        /> */}
                      </Flex>
                    </div>
                  )}

                  {activeForm.numericalState === 2 && (
                    <div>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          First Name*
                        </Typography>
                        <Input
                          name="firstName"
                          value={values["firstName"]}
                          placeholder="Please enter your first name"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("firstName", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.firstName && errors?.firstName
                              ? errors?.firstName
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Last Name*
                        </Typography>
                        <Input
                          name="lastName"
                          value={values["lastName"]}
                          placeholder="Please enter your last name"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("lastName", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.lastName && errors?.lastName
                              ? errors?.lastName
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Phone Number*
                        </Typography>
                        <Input
                          name="phoneNumber"
                          value={values["phoneNumber"]}
                          placeholder="Please enter your phone number"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("phoneNumber", value);
                          }}
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.phoneNumber && errors?.phoneNumber
                              ? errors?.phoneNumber
                              : ""
                          }
                        />
                      </Flex>
                      <Flex direction="column" margin="10px 0px 20px">
                        <Typography
                          className="form-label"
                          size="xs"
                          fontWeight="medium"
                          color={colors.lightText}
                        >
                          Email Address*
                        </Typography>
                        <Input
                          name="email"
                          type="email"
                          value={values["emailAddress"]}
                          placeholder="Please enter your email address"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("emailAddress", value);
                          }}
                          autocomplete="off"
                          style={{
                            lineHeight: 0,
                          }}
                        />
                        <FormError
                          error={
                            touched?.emailAddress && errors?.emailAddress
                              ? errors?.emailAddress
                              : ""
                          }
                        />
                      </Flex>

                      <Typography style={{ marginBottom: "10px" }} size="md">
                        Are you interested in additional building works?
                      </Typography>
                      <Flex style={{ marginBottom: "20px" }}>
                        <Flex alignItems="flex-start" gap="12px">
                          <Checkbox
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const interest = [...values.interest];
                              if (checked) {
                                interest.push("Renovation");
                              } else {
                                const index = interest.indexOf("Renovation");
                                if (index > -1) {
                                  interest.splice(index, 1);
                                }
                              }
                              setFieldValue("interest", interest);
                            }}
                            isChecked={values.interest.includes("Renovation")}
                          />
                          <Typography
                            style={{
                              padding: 0,
                              margin: 0,
                              lineHeight: "16px",
                            }}
                            color={theme.colors.gray8C}
                            size="md"
                          >
                            Renovation
                          </Typography>
                        </Flex>
                        <Flex alignItems="flex-start" gap="12px">
                          <Checkbox
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const interest = [...values.interest];
                              if (checked) {
                                interest.push("Extension");
                              } else {
                                const index = interest.indexOf("Extension");
                                if (index > -1) {
                                  interest.splice(index, 1);
                                }
                              }
                              setFieldValue("interest", interest);
                            }}
                            isChecked={values.interest.includes("Extension")}
                          />
                          <Typography
                            style={{
                              padding: 0,
                              margin: 0,
                              lineHeight: "16px",
                            }}
                            color={theme.colors.gray8C}
                            size="md"
                          >
                            Extension
                          </Typography>
                        </Flex>
                        <Flex alignItems="flex-start" gap="12px">
                          <Checkbox
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const interest = [...values.interest];
                              if (checked) {
                                interest.push("Change of layout");
                              } else {
                                const index =
                                  interest.indexOf("Change of layout");
                                if (index > -1) {
                                  interest.splice(index, 1);
                                }
                              }
                              setFieldValue("interest", interest);
                            }}
                            isChecked={values.interest.includes(
                              "Change of layout"
                            )}
                          />
                          <Typography
                            style={{
                              padding: 0,
                              margin: 0,
                              lineHeight: "16px",
                            }}
                            color={theme.colors.gray8C}
                            size="md"
                          >
                            Change of layout
                          </Typography>
                        </Flex>
                      </Flex>

                      <Typography
                        color={theme.colors.gray8C}
                        size={"sm"}
                        style={{ marginBottom: "8px" }}
                        fontWeight="medium"
                      >
                        Our Newsletter*
                      </Typography>
                      <Typography color={theme.colors.gray8C} size={"xs"}>
                        *Opt in to EnergyFix’s Newsletter by email and stay in
                        touch with retrofitting news.
                      </Typography>
                      <Flex
                        alignItems="center"
                        gap="30px"
                        margin="12px 0px 30px"
                      >
                        <Radio
                          label="Yes, Please"
                          value={"yes"}
                          checked={values.subscribeNewsletter === "yes"}
                          name="subscribeNewsletter"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("subscribeNewsletter", value);
                          }}
                        />
                        <Radio
                          label="No, Thanks"
                          value={"no"}
                          checked={values.subscribeNewsletter === "no"}
                          name="subscribeNewsletter"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("subscribeNewsletter", value);
                          }}
                        />
                      </Flex>
                      <FormError
                        error={
                          touched?.subscribeNewsletter &&
                          errors?.subscribeNewsletter
                            ? errors?.subscribeNewsletter
                            : ""
                        }
                      />
                      <Flex alignItems="flex-start" gap="12px">
                        <Checkbox
                          isChecked={values.agreeTermsAndConditions}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            setFieldValue("agreeTermsAndConditions", checked);
                          }}
                        />
                        <Typography
                          style={{ padding: 0, margin: 0, lineHeight: "16px" }}
                          color={theme.colors.gray8C}
                          size="xs"
                        >
                          I have read, understood and agree to the Terms &
                          Conditions and Data Privacy Statement*
                        </Typography>
                      </Flex>
                      <FormError
                        error={
                          touched?.agreeTermsAndConditions &&
                          errors?.agreeTermsAndConditions
                            ? errors?.agreeTermsAndConditions
                            : ""
                        }
                      />
                    </div>
                  )}

                  <div
                    style={{ display: "flex", gap: "20px", marginTop: "40px" }}
                  >
                    {(activeForm.numericalState > 1 ||
                      activeForm.propertyLabelIndex > 0) && (
                      <Button
                        htmlType="button"
                        style={{
                          margin: "0 auto",
                          width: "100%",
                          backgroundColor: theme.colors.blue.light,
                          color: theme.colors.black,
                        }}
                        onClick={onBackPressed}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      htmlType="submit"
                      isLoading={loading}
                      style={{ margin: "0 auto", width: "100%" }}
                    >
                      {activeForm.numericalState === 1 ? "Continue" : "Submit"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Modal
          open={isModalOpen.isOpen}
          onClose={() =>
            setModalOpen({ status: "", errorMessage: "", isOpen: false })
          }
          size="md"
          isCentered
          style={{ maxWidth: "625px" }}
        >
          {isModalOpen.status === "success" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <img src="/assets/icons/successTick.svg" />
              <SectionTitle style={{ fontSize: "20px" }}>
                Your{" "}
                <ColorText color={theme.colors.primary}>interest</ColorText> has
                been successfully{" "}
                <ColorText color={theme.colors.secondary}>submitted.</ColorText>
              </SectionTitle>
              <Typography>
                A member of staff will endeavor to be in contact within 3
                working days.
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <SectionTitle style={{ fontSize: "20px" }}>
                <ColorText color={theme.colors.error}>Error:</ColorText>{" "}
                {isModalOpen.errorMessage} <br />
              </SectionTitle>
            </div>
          )}
        </Modal>
      </SubmitInterestStyled>
    </Container>
  );
};

export default SubmitInterest;
