import { Helmet } from "react-helmet-async";
import { ServiceSection, ServicesHeader } from "../../components/Services";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - Our Services</title>
        <meta
          name="description"
          content="EnergyFix offers tailored services for homeowners, including whole house retrofit and commercial upgrades, aiming for warmth, comfort, and efficiency."
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <ServicesHeader />
      <ServiceSection />
    </>
  );
};

export default Services;
