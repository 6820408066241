import windowSVG from "../../../assets/temp/window.svg";
import doorSVG from "../../../assets/temp/door.svg";
import heatingControl from "../../../assets/temp/heatControl.svg";
import electricChargerSVG from "../../../assets/temp/electricCharger.svg";
import heatPumpSVG from "../../../assets/temp/heatPump.svg";
import roofSVG from "../../../assets/temp/roof.svg";

export const hotspot = [
  // Example
  //   {
  //     position: {
  //       left: (leftOffset / totalWidth) * 100 + "%",
  //       top: (topOffset / totalHeight) * 100 + "%",
  //     },
  //     content: {
  //   image: "",
  //   title: "Radiator",
  //   description: "",
  //   onClick: () => {},
  // },
  //   },
  {
    position: {
      left: (270 / 426) * 100 + "%",
      top: (295 / 426) * 100 + "%",
    },
    content: {
      image: heatingControl,
      title: "Heating Controls",
      description:
        "Installing Heating Controls to your home heating system will typically reduce your energy usage by up to 20%. Home heating systems without heating controls can be wasteful of energy and costly to run because there is little to no control of the amount of heat being used to heat your home and your hot water.",
      onClick: () => {
        alert("Temperature Control");
      },
    },
  },
  {
    position: {
      left: (220 / 426) * 100 + "%",
      top: (300 / 426) * 100 + "%",
    },
    content: {
      image: doorSVG,
      title: "Door & Window Upgrades",
      description:
        "Banish draughts from windows and doors, enhancing comfort and trimming heating bills. Upgrade to modern standards to reduce outside noise and heat loss by 10%. SEAI grants for windows range from €1,500 to €4,000, while external door grants are €800 per door (maximum of 2).",
      onClick: () => {
        alert("Door");
      },
    },
  },
  {
    position: {
      left: (130 / 426) * 100 + "%",
      top: (340 / 426) * 100 + "%",
    },
    content: {
      image: heatPumpSVG,
      title: "Heat Pump Installation",
      description:
        "Elevate your home's comfort with a heat pump, replacing your existing heating system to slash costs, energy usage, and greenhouse gas emissions. Avail the SEAI grant ranging from €3,500 to €6,500.",
      onClick: () => {
        alert("Air Conditioner");
      },
    },
  },
  // {
  //   position: {
  //     left: (125 / 426) * 100 + "%",
  //     top: (305 / 426) * 100 + "%",
  //   },
  //   content: {
  //     image: heatPumpSVG,
  //     title: "Ventilation",
  //     description:
  //       "Ensuring proper ventilation is essential for a healthy living environment. Sealing up a drafty house isn't about preventing fresh air exchange; it's about managing it effectively.",
  //     onClick: () => {
  //       alert("Ventilation");
  //     },
  //   },
  // },
  {
    position: {
      left: (145 / 426) * 100 + "%",
      top: (205 / 426) * 100 + "%",
    },
    content: {
      image: windowSVG,
      title: "Door & Window Upgrades",
      description:
        "Banish draughts from windows and doors, enhancing comfort and trimming heating bills. Upgrade to modern standards to reduce outside noise and heat loss by 10%. SEAI grants for windows range from €1,500 to €4,000, while external door grants are €800 per door (maximum of 2).",
      onClick: () => {
        alert("Window");
      },
    },
  },
  {
    position: {
      left: (295 / 426) * 100 + "%",
      top: (175 / 426) * 100 + "%",
    },
    content: {
      image: electricChargerSVG,
      title: "Solar Energy Systems",
      description:
        "Embrace Ireland's climate by harnessing solar energy, which can fulfill 50-60% of your electricity or hot water needs, slashing your energy bills. Consider installing a solar thermal system for hot water or solar panels for electricity. Avail the SEAI grant of €1,200 for solar water heating and up to €2,400 for panels.",
      onClick: () => {
        alert("Solar");
      },
    },
  },
  {
    position: {
      left: (210 / 426) * 100 + "%",
      top: (122 / 426) * 100 + "%",
    },
    content: {
      image: roofSVG,
      title: "Roof & wall insulation",
      description:
        "Combat high heating costs in poorly insulated homes. Upgrade your roof or attic insulation to cut heat loss by up to 30%, often the most cost-effective energy upgrade. SEAI grants for attic insulation range from €800 to €1,500. Enhance your home's energy efficiency by insulating external walls, reducing heat loss by up to 35%. Opt for cavity, external, or internal insulation, with SEAI grants ranging from €700 to €8,000. ",
      onClick: () => {
        alert("Chimney");
      },
    },
  },
];
