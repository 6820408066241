import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Typography from "@shared/designComponents/Typography";

import {
  HeaderContainer,
  HeaderDropdown,
  MobileHeaderNavigation,
  MobileHeaderDropdown,
  Spacer,
  AlternateSignIn,
  SignInWithSocial,
} from "./style";

import colors from "@shared/theme/colors";
import { useEffect, useState } from "react";
import Accordion from "@shared/designComponents/Accordion/Accordion";

import { useWindowScroll, useWindowSize } from "@uidotdev/usehooks";
import { useRoot } from "@shared/context/RootProvider";
import Container from "@shared/designComponents/Container";
import {
  ABOUTUS_ROUTE,
  COST_GRANT_PATH,
  FAQS_ROUTE,
  FINANCE_PATH,
  RETROFIT_ROUTE,
  SERVICES_ROUTE,
  USER_DASHBOARD_ROUTE,
  VACANT_HOMES_GRANT_PATH,
} from "../../routes/routePaths";
import {
  Button,
  ColorText,
  Input,
  Modal,
  SectionTitle,
} from "@shared/designComponents";
import { Form, Formik } from "formik";
import validation from "./validation";
import { toast } from "react-toastify";
import { requestAPI } from "@shared/utils";
import authConfig from "@shared/apiConfigs/auth";
import axios from "axios";
import energyConstants from "@shared/constants";
import AccountSignIn from "./AccountSignIn";

const Header = () => {
  const [{ y }] = useWindowScroll();

  return (
    <>
      <HeaderContainer>
        {/* <TopHeader /> */}
        <MainHeader scrollY={y} />
      </HeaderContainer>
      {/* TODO: Create a Spacer Component with size props, now: just a styled component */}
      {y ? <>{y > 38 && <Spacer />}</> : null}
    </>
  );
};

function TopHeader() {
  return (
    <div className="top-header">
      <Typography className="top-header__text" size="xs">
        Want a warmer home ? See what grants are available to you with SEAI
      </Typography>
    </div>
  );
}
function MainHeader({ scrollY }: { scrollY?: number | null }) {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const [activePath, setActivePath] = useState("");
  const { width } = useWindowSize();
  const { auth } = useRoot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setAuth, setAccessToken, updateAuth } = useRoot();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  function handleMouseEnter(path: string) {
    setActivePath(path);
    setOpen(false);
  }

  const activePathOptions = navList.filter(
    (nav) => nav.path === "/cost-and-fees"
  )[0]?.options;

  useEffect(() => {
    if (searchParams.get("openLogin")) {
      handleOpenModal();
      searchParams.delete("openLogin");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleClientLogin = async (values: {
    email: string;
    password: string;
  }) => {
    try {
      setIsLoading(true);
      const loginData = await requestAPI(authConfig.login(values));
      if (loginData?.user?._id) {
        updateAuth(loginData?.user);
        localStorage.setItem(
          energyConstants.LOCAL_STORAGE_KEY.accessKey,
          loginData?.token
        );
        axios.defaults.headers.Authorization = `Bearer ${loginData?.token}`;
        localStorage.setItem(
          energyConstants.LOCAL_STORAGE_KEY.refreshKey,
          loginData?.refreshToken
        );
        setIsModalOpen(false);
        navigate(USER_DASHBOARD_ROUTE, { replace: true });
        toast.success("Logged in successfully");
        setAccessToken(loginData.token);
        setAuth(loginData.user);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  };

  return (
    <div
      className="main-header__wrapper"
      style={{ position: scrollY && scrollY > 38 ? "fixed" : "relative" }}
    >
      <Container enableMargin={false}>
        <div className="main-header">
          <Link to="/">
            <img
              src="/assets/images/logo.svg"
              alt="site logo"
              className="main-header__logo"
            />
          </Link>

          <button
            className="main-header__ham-icon"
            onClick={() => setOpen(true)}
          >
            <img src="/assets/icons/ham-menu.svg" alt="open menu icon" />
          </button>

          <MobileHeaderNavigation $open={open}>
            <div className="header-nav">
              <button
                className="header-nav__btn--close"
                onClick={() => setOpen(false)}
              >
                <img src="/assets/icons/close.svg" alt="close menu icon" />
              </button>
              <div className="header-nav__list">
                {navList.map((nav) => {
                  if (width && width < 992 && nav.options) {
                    return (
                      <MobileHeaderDropdown
                        key={nav.path}
                        className="mobile-dropdown"
                      >
                        {nav.options ? (
                          <MobileDropdownComponent
                            title={nav.name}
                            options={nav.options}
                            className={{
                              header: "firstHeader",
                              list: "firstList",
                            }}
                            i={0}
                            handleCloseDrawer={handleCloseDrawer}
                          />
                        ) : (
                          <li key={nav.path}>
                            <Typography
                              variant="span"
                              size={["xs"]}
                              color={colors.gray.dark}
                              fontWeight="semibold"
                              className="mobile-dropdown__list1-title"
                            >
                              {nav.name}
                            </Typography>
                          </li>
                        )}
                      </MobileHeaderDropdown>
                    );
                  }

                  return (
                    <Link
                      to={nav.path}
                      key={nav.name}
                      className="nav-link"
                      onClick={() => {
                        handleMouseEnter(nav.path);
                      }}
                    >
                      <Typography
                        variant="span"
                        size={["sm"]}
                        color={
                          pathname === nav.path
                            ? colors.secondary
                            : colors.gray.dark
                        }
                        fontWeight="medium"
                      >
                        {nav.name}
                      </Typography>
                    </Link>
                  );
                })}
              </div>

              {!auth ? (
                <button
                  className="header-nav__btn--signin"
                  onClick={handleOpenModal}
                >
                  Client Sign In
                </button>
              ) : (
                <AccountSignIn />
              )}
            </div>
          </MobileHeaderNavigation>

          {activePath === "/cost-and-fees" && (
            <DesktopDropdownComponent
              options={activePathOptions}
              onMouseLeave={() => setActivePath("")}
            />
          )}
        </div>
      </Container>
      <Modal open={isModalOpen} onClose={handleCloseModal} size="lg">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => handleClientLogin(values)}
          enableReinitialize
          validationSchema={validation}
        >
          {({ values, handleSubmit, setFieldValue, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div>
                  <SectionTitle style={{ fontSize: "20px", marginBottom: 20 }}>
                    <ColorText color="#20435D">Sign In</ColorText> to get access
                    to
                    <ColorText color="#26C9A4"> energyFix</ColorText>
                  </SectionTitle>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography>Email Address*</Typography>
                    <Input
                      name="email"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                    {touched.email && errors.email && (
                      <Typography size="sm" style={{ color: "red" }}>
                        {errors.email}
                      </Typography>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography>Password*</Typography>
                    <Input
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    {touched.password && errors.password && (
                      <Typography size="sm" style={{ color: "red" }}>
                        {errors.password}
                      </Typography>
                    )}
                  </div>
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "#20435D",
                      marginBottom: "20px",
                    }}
                  >
                    Forgot Password?
                  </Typography>

                  <Button
                    style={{ marginBottom: "20px", width: "100%" }}
                    htmlType="submit"
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

function DesktopDropdownComponent({
  options,
  onMouseLeave,
}: {
  options: NavType[] | undefined;
  onMouseLeave?: () => void;
}) {
  const [activeSubPath, setActiveSubPath] = useState("");

  if (!options) {
    return null;
  }

  return (
    <HeaderDropdown>
      <div className="header-dropdown-container" onMouseLeave={onMouseLeave}>
        <nav className="header-dropdown-nav">
          <ul className="header-dropdown-nav__list">
            {options &&
              options.map((option) => {
                return (
                  <li
                    key={option.path}
                    className="header-dropdown-nav__list-item1"
                  >
                    <Link to={option.path}>
                      <Typography
                        size="sm"
                        fontWeight="medium"
                        variant="span"
                        color={colors.normalText}
                      >
                        {option.name}
                      </Typography>
                    </Link>

                    {option.options && (
                      <ul>
                        {option.options.map((secOpt) => {
                          return (
                            <li
                              key={secOpt.path}
                              className="header-dropdown-nav__list-item2"
                            >
                              <Link
                                to={secOpt.path}
                                onClick={() => setActiveSubPath(secOpt.path)}
                              >
                                <Typography
                                  size="xs"
                                  fontWeight="medium"
                                  variant="span"
                                  color={
                                    activeSubPath === secOpt.path
                                      ? colors.primary
                                      : colors.lightText
                                  }
                                >
                                  {secOpt.name}
                                </Typography>
                              </Link>

                              <ul
                                style={{
                                  display:
                                    activeSubPath === secOpt.path
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {secOpt.options &&
                                  secOpt.options.map((thirdOpt) => {
                                    return (
                                      <li
                                        key={thirdOpt.path}
                                        className="header-dropdown-nav__list-item3"
                                      >
                                        <Link to={thirdOpt.path}>
                                          <Typography
                                            variant="span"
                                            size="xs"
                                            color={colors.lightText}
                                          >
                                            {thirdOpt.name}
                                          </Typography>
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </HeaderDropdown>
  );
}

function MobileDropdownComponent({
  title,
  options,
  className,
  i,
  handleCloseDrawer,
}: {
  title: string;
  options: NavType[];
  className: { header: string; list: string };
  i?: number;
  handleCloseDrawer: () => void;
}) {
  return (
    <Accordion>
      <Accordion.Header
        className={`mobile-dropdown__header-${className.header}`}
      >
        <Typography
          variant="span"
          size={["sm"]}
          color={colors.gray.dark}
          fontWeight="medium"
        >
          {title}
        </Typography>
      </Accordion.Header>

      <Accordion.Content animationType="display">
        <ul>
          {options.map((opt, index) => {
            if (opt.options) {
              return (
                <MobileDropdownComponent
                  title={opt.name}
                  options={opt.options}
                  key={opt.path}
                  className={{
                    header: `${i}-${index + 1}`,
                    list: `sub-${index}`,
                  }}
                  i={index + 1}
                  handleCloseDrawer={handleCloseDrawer}
                />
              );
            }

            return (
              <li
                key={opt.path}
                className={`mobile-dropdown__header-${i}-${index + 1}`}
              >
                <Link to={opt.path} onClick={handleCloseDrawer}>
                  <Typography
                    variant="span"
                    size={["sm"]}
                    color={colors.gray.dark}
                    fontWeight="medium"
                    className={`mobile-dropdown__list-title`}
                  >
                    {opt.name}
                  </Typography>
                </Link>
              </li>
            );
          })}
        </ul>
      </Accordion.Content>
    </Accordion>
  );
}

export default Header;

type NavType = {
  path: string;
  name: string;
  options?: NavType[];
};

const navList: NavType[] = [
  {
    path: `${ABOUTUS_ROUTE}`,
    name: "About Us",
  },
  {
    path: `${RETROFIT_ROUTE}`,
    name: "What is Retrofitting?",
  },
  {
    path: `${SERVICES_ROUTE}`,
    name: "Our services",
  },
  {
    path: `${COST_GRANT_PATH}`,
    name: "Cost & Grants",
    options: [
      {
        name: "SEAI Grants",
        path: `${COST_GRANT_PATH}`,
        // options: [
        //   {
        //     name: "Heating",
        //     path: "#heating",
        //     options: [
        //       {
        //         name: "Heating 1",
        //         path: "#heading-1",
        //       },
        //       {
        //         name: "Heating 2",
        //         path: "#heading-2",
        //       },
        //       {
        //         name: "Heating 3",
        //         path: "#heading-3",
        //       },
        //     ],
        //   },
        //   {
        //     path: "/insulation1",
        //     name: "Insulation",
        //   },
        //   {
        //     path: "/insulation2",
        //     name: "Insulation",
        //   },
        //   {
        //     path: "/insulation3",
        //     name: "Insulation",
        //   },
        //   {
        //     path: "/insulation4",
        //     name: "Insulation",
        //   },
        // ],
      },
      {
        name: "Vacant Home Grants",
        path: `${COST_GRANT_PATH}/${VACANT_HOMES_GRANT_PATH}`,
        // options: [
        //   {
        //     path: "#applicatio",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "#applicatn",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "#applicaion",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "#faqs",
        //     name: "FAQS",
        //   },
        // ],
      },
      {
        name: "Finance",
        path: `${COST_GRANT_PATH}/${FINANCE_PATH}`,
        // options: [
        //   {
        //     path: "/application-1",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "/application-2",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "/application-3",
        //     name: "Application Form",
        //   },
        //   {
        //     path: "/faqs",
        //     name: "FAQS",
        //   },
        // ],
      },
    ],
  },
  {
    path: `${FAQS_ROUTE}`,
    name: "FAQs",
  },
];
