import { Helmet } from "react-helmet-async";
import FinancePage from "../../../components/CostAndGrants/Finance";

const Finance = () => {
  return (
    <>
      <Helmet>
        <title>EnergyFix - Finance Solutions</title>
        <meta
          name="description"
          content="Explore various financing options for your home energy upgrade. From green loans to credit unions, find the best fit for your project's needs and budget."
        />
        <link rel="canonical" href="/cost-and-fees/finance" />
      </Helmet>
      <FinancePage />
    </>
  );
};

export default Finance;
