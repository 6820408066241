import moment from "moment";
import { GrantsDataType } from ".";
import { FileDownload } from "../Documents/helperFunction";
export const formatGrantsTableData = (
  data: GrantsDataType[]
): GrantsDataType[] => {
  return data.map((item) => {
    const newGrants: any = Object.entries(item)
      .map(([key, value]) => {
        switch (key) {
          case "grantNameId":
            return {
              label: "grants",
              value: value?.name,
            };
          case "buildingTypeId":
            return {
              label: "building_types",
              value: value?.buildingName,
            };
          case "document":
            return {
              label: "attachments",
              value: <FileDownload value={value} />,
            };
          case "reliefAmount":
            return {
              label: "reliefAmount",
              value: `€ ${value.toLocaleString()}`,
            };

          case "updatedAt":
            return {
              label: "updated_date",
              value: moment(value).startOf("minute").fromNow(),
            };
          case "grantStatus":
            return { label: "status", value: value };

          default:
            return null;
        }
      })
      .filter(Boolean); // Remove null entries
    return newGrants;
  });
};
