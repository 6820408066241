import { Typography } from "@shared/designComponents";
import theme from "@shared/theme";
import { UpdateCardStyled } from "./style";
import moment from "moment";

type Props = {
  title: string;
  name?: string;
  date: string;
  nameColor?: string;
  imgUrl?: string;
};

const Update = ({ title, name, date, nameColor, imgUrl }: Props) => {
  return (
    <UpdateCardStyled>
      <div
        className="iconBox"
        style={{
          backgroundColor: nameColor || theme.colors.secondary,
        }}
      />
      <div className="details">
        <Typography size={"sm"} fontWeight="normal" className="title">
          {title || "This is test"}
        </Typography>
        <Typography
          size={"xs"}
          fontWeight="medium"
          className="name"
          color={nameColor}
        >
          {name}
        </Typography>
        <Typography size={"xs"} className="date" color={theme.colors.gray8A}>
          {moment(date).startOf("minutes").fromNow() || "2024-1-28"}
        </Typography>
      </div>
    </UpdateCardStyled>
  );
};

export default Update;
